import styled, { css } from "styled-components";
import { IElementButton } from "@ecp-redux/dto/themeSettings/settingsPatterns.types";
import {
  TButtonId,
  TButtonReadoutValue,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import ConditionalHTMLElement from "../../../global/components/ConditionalHTMLElement/ConditionalHTMLElement";
import composeCss from "../../../settingsPatterns/composeCss";
import {
  convertButtonIdToValues,
  convertColorIdToHex,
} from "../../../settingsPatterns/settingsPatterns.methods";
import { TButtonList } from "@ecp-boxes/boxes/BoxListOfButtons/BoxListOfButtons.scheme";

export interface IButtonSettings {
  $settings: IElementButton | TButtonId | TButtonReadoutValue;
  $uniqueIcon?: TButtonList["icon"];
}

const StyledButtonWrapper = styled(ConditionalHTMLElement).attrs((props) => ({
  renderAs: props?.["renderAs"] ?? "a",
  show:
    props?.["$settings"]?.show === false || props["show"] === false
      ? false
      : true,
  target: props?.["target"] ? "_blank" : "_parent",
}))<IButtonSettings>(({ $settings, theme, $uniqueIcon }) => {
  const buttonValue = convertButtonIdToValues(
    $settings,
    theme?.globalObjects?.buttons
  );

  const icon = $uniqueIcon ?? buttonValue.icon;

  return css`
    display: flex;
    align-items: center;
    justify-content: center;
    ${composeCss.clampPadding(
      {
        top: buttonValue.verticalPadding,
        right: buttonValue.horizontalPadding,
        bottom: buttonValue.verticalPadding,
        left: buttonValue.horizontalPadding,
      },
      theme
    )};
    height: fit-content;
    width: fit-content;
    border-style: solid;
    ${composeCss.buttonSettings.icon(icon)};
    ${composeCss.font(buttonValue.font, theme.typography)};
    ${composeCss.buttonSettings.borderColor(buttonValue.border, theme)};
    ${composeCss.buttonSettings.borderWidth(
      buttonValue.border.widthV2,
      buttonValue.border.width
    )};
    ${composeCss.buttonSettings.borderRadius(
      buttonValue.border.radiusV2,
      buttonValue.border.radius
    )};
    background-color: ${convertColorIdToHex(
      buttonValue.background.color,
      theme.colorPalette
    )};
    color: ${convertColorIdToHex(buttonValue.text.color, theme.colorPalette)};

    .svg {
      height: ${icon.size}px;
      width: ${icon.size}px;
      background-color: ${convertColorIdToHex(icon.color, theme.colorPalette)};
      mask: url(${icon.iconUrl}) no-repeat center / contain;
      -webkit-mask: url(${icon.iconUrl}) no-repeat center / contain;
    }

    &:hover {
      background-color: ${convertColorIdToHex(
        buttonValue.background.hoverColor,
        theme.colorPalette
      )};
      color: ${convertColorIdToHex(
        buttonValue.text.hoverColor,
        theme.colorPalette
      )};
      border-color: ${convertColorIdToHex(
        buttonValue.border.hoverColor,
        theme.colorPalette
      )};
      .svg {
        background-color: ${convertColorIdToHex(
          icon.hoverColor,
          theme.colorPalette
        )};
      }
    }

    &:active,
    &.active-button {
      background-color: ${convertColorIdToHex(
        buttonValue.background.activeColor,
        theme.colorPalette
      )};
      color: ${convertColorIdToHex(
        buttonValue.text.activeColor,
        theme.colorPalette
      )};
      border-color: ${convertColorIdToHex(
        buttonValue.border.activeColor,
        theme.colorPalette
      )};
      .svg {
        background-color: ${convertColorIdToHex(
          icon.activeColor,
          theme.colorPalette
        )};
      }
    }

    &:disabled,
    &.disabled-button {
      background-color: ${convertColorIdToHex(
        buttonValue.background.disabledColor,
        theme.colorPalette
      )};
      color: ${convertColorIdToHex(
        buttonValue.text.disabledColor,
        theme.colorPalette
      )};
      border-color: ${convertColorIdToHex(
        buttonValue.border.disabledColor,
        theme.colorPalette
      )};
      user-select: none;
      pointer-events: none;
      .svg {
        background-color: ${convertColorIdToHex(
          icon.disabledColor,
          theme.colorPalette
        )};
      }
    }
    /* Smartphones and touchscreens - Fix for stop applying hover styles after clicking on button on smartphones and touchscreens (ECP-8289) */
    @media ((hover: none) or (hover: hover)) and (pointer: coarse) {
      &:hover {
        // default state colors applied for hover here
        background-color: ${convertColorIdToHex(
          buttonValue.background.color,
          theme.colorPalette
        )};
        border-color: ${convertColorIdToHex(
          buttonValue.border.color,
          theme.colorPalette
        )};
        color: ${convertColorIdToHex(
          buttonValue.text.color,
          theme.colorPalette
        )};
        .svg {
          background-color: ${convertColorIdToHex(
            icon.color,
            theme.colorPalette
          )};
        }
      }
      &:active,
      &.active-button {
        background-color: ${convertColorIdToHex(
          buttonValue.background.activeColor,
          theme.colorPalette
        )};
        color: ${convertColorIdToHex(
          buttonValue.text.activeColor,
          theme.colorPalette
        )};
        border-color: ${convertColorIdToHex(
          buttonValue.border.activeColor,
          theme.colorPalette
        )};
        .svg {
          background-color: ${convertColorIdToHex(
            icon.activeColor,
            theme.colorPalette
          )};
        }
      }
      &:disabled,
      &.disabled-button {
        background-color: ${convertColorIdToHex(
          buttonValue.background.disabledColor,
          theme.colorPalette
        )};
        color: ${convertColorIdToHex(
          buttonValue.text.disabledColor,
          theme.colorPalette
        )};
        border-color: ${convertColorIdToHex(
          buttonValue.border.disabledColor,
          theme.colorPalette
        )};
        user-select: none;
        pointer-events: none;
        .svg {
          background-color: ${convertColorIdToHex(
            icon.disabledColor,
            theme.colorPalette
          )};
        }
      }
    }
  `;
});

export default StyledButtonWrapper;
