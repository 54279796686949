import { z } from "zod";
import { EVENT_ORIGIN } from "./const";

const SiteTypeSchema = z.enum(["m", "d"]);
export type ISiteType = z.infer<typeof SiteTypeSchema>;

export const AddToCartEventSchema = z.object({
  product_id: z.ostring().nullable().default(null),
  customer_id: z.onumber().nullable().default(null),
  sales_price: z.onumber().nullable().default(null),
  net_sales_price: z.onumber().nullable().default(null),
  base_price: z.onumber().nullable().default(null),
  suggested_retail_price: z.onumber().nullable().default(null),
  quantity: z.onumber().nullable().default(null),
  currency: z.ostring().nullable().default("zł"),
  origin: z.nativeEnum(EVENT_ORIGIN).nullable().default(null),
});
export type IAddToCartEvent = z.infer<typeof AddToCartEventSchema>;

export const RemoveFromCartEventSchema = z.object({
  product_id: z.ostring().nullable().default(null),
  customer_id: z.onumber().nullable().default(null),
  sales_price: z.onumber().nullable().default(null),
  net_sales_price: z.onumber().nullable().default(null),
  base_price: z.onumber().nullable().default(null),
  suggested_retail_price: z.onumber().nullable().default(null),
  quantity: z.onumber().nullable().default(null),
  currency: z.ostring().nullable().default("zł"),
  origin: z.nativeEnum(EVENT_ORIGIN).nullable().default(null),
});

export type IRemoveFromCartEvent = z.infer<typeof RemoveFromCartEventSchema>;

export const ViewPageEventSchema = z.object({
  page_name: z.ostring().nullable().default(null),
  url: z.ostring().nullable().default(null),
  customer_id: z.onumber().nullable().default(null),
  site_type: SiteTypeSchema.nullable().optional().default(null),
  email: z.ostring().nullable().default(null),
});
export type IViewPageEvent = z.infer<typeof ViewPageEventSchema>;

export const ProductDetailsEventSchema = z.object({
  product_id: z.ostring().nullable().default(null),
  category_id: z.ostring().nullable().default(null),
  customer_id: z.onumber().nullable().default(null),
  site_type: SiteTypeSchema.nullable().optional().default(null),
  email: z.ostring().nullable().default(null),
});
export type IProductDetailsEvent = z.infer<typeof ProductDetailsEventSchema>;

export const CartProductSchema = z.object({
  product_id: z.ostring().nullable().default(null),
  sales_price: z.onumber().nullable().default(null),
  net_sales_price: z.onumber().nullable().default(null),
  quantity: z.onumber().nullable().default(null),
});
export type ICartProduct = z.infer<typeof CartProductSchema>;

export const ViewCartEventSchema = z.object({
  products: z.array(CartProductSchema).default([]),
  currency: z.ostring().nullable().default("zł"),
  customer_id: z.onumber().nullable().default(null),
  email: z.ostring().nullable().default(null),
  site_type: SiteTypeSchema.nullable().optional().default(null),
});
export type IViewCartEvent = z.infer<typeof ViewCartEventSchema>;

export const ListingTrackerEventSchema = z.object({
  origin: z.nativeEnum(EVENT_ORIGIN).nullable().default(null),
  category_id: z.ostring().nullable().default(null),
  products: z.array(z.ostring().nullable()).default([]),
  customer_id: z.onumber().nullable().default(null),
  site_type: SiteTypeSchema.nullable().optional().default(null),
  email: z.ostring().nullable().default(null),
});
export type IListingTrackerEvent = z.infer<typeof ListingTrackerEventSchema>;

export const TransactionEventSchema = z.object({
  products: z.array(CartProductSchema).default([]),
  transaction_id: z.ostring().nullable().default(null),
  currency: z.ostring().nullable().default("zł"),
  customer_id: z.onumber().nullable().default(null),
  site_type: SiteTypeSchema.nullable().optional().default(null),
  email: z.ostring().nullable().default(null),
});
export type ITransactionEvent = z.infer<typeof TransactionEventSchema>;

export const SalesTrackingEventSchema = z.object({
  amount: z.onumber().nullable().default(null),
  event_id: z.ostring().nullable().default(null),
  pay_name: z.ostring().nullable().default(null),
  currency: z.ostring().nullable().default("zł"),
  email: z.ostring().nullable().default(null),
  catalog_promo_id: z.ostring().nullable().default(null),
  cart_promo_id: z.ostring().nullable().default(null),
  net_amount: z.onumber().nullable().default(null),
  products: z.array(CartProductSchema).default([]),
});
export type ISalesTrackingEvent = z.infer<typeof SalesTrackingEventSchema>;
