import styled, { css } from "styled-components";

import { IBoxMultiMenuSettings } from "../../boxes/BoxMultiMenu/StyledMultiMenu.types";
import { alignmentOptionsToValue } from "../../composeCss";
import { convertColorIdToHex } from "../../settingsPatterns.methods";

const oppositeNumber = (v: number) => v * -1;

const StyledMultiMenuHorizontal = styled.div<
  IBoxMultiMenuSettings & { activeGroup: number; width: number }
>(({ $settings, theme }) => {
  const {
    spacing: {
      itemHeader: {
        bottom: itemHeaderBottom,
        left: itemHeaderLeft,
        right: itemHeaderRight,
        top: itemHeaderTop,
      },
      itemHeaderGap,
    },
  } = $settings;
  return css`
    display: flex;

    .groups {
      display: flex;
      justify-content: ${alignmentOptionsToValue(
        $settings.contentAlignment.group
      )};
      align-items: center;
      width: 100%;
      position: relative;
      ${itemHeaderGap !== 0 && `gap: ${itemHeaderGap}px;`}
      ${itemHeaderTop !== 0 && `padding-top: ${itemHeaderTop}px;`}
      ${itemHeaderRight !== 0 && `padding-right: ${itemHeaderRight}px;`}
      ${itemHeaderBottom !== 0 && `padding-bottom: ${itemHeaderBottom}px;`}
      ${itemHeaderLeft !== 0 && `padding-left: ${itemHeaderLeft}px;`}
      &::after {
        display: block;
        content: "";
        height: 1px;
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
      }

      & .group {
        ${$settings.underline.show
          ? `padding-bottom: ${$settings.underline.gap}px;`
          : ""}

        // adding underline to active group
    ${$settings.underline.show
          ? `
          &::after {
            display: flex;
            position: relative;
            content: "";
            z-index: var(--zindex-dropdown);
            background-color: transparent;
            width: 100%;
            height: ${$settings.underline.thickness}px;
            bottom: ${oppositeNumber($settings.underline.gap)}px;
          }

          &.active::after {
            background-color: ${convertColorIdToHex(
              $settings.underline.color,
              theme.colorPalette
            )};
          }
    `
          : ""}

      & > a {
          display: flex;
          justify-content: ${alignmentOptionsToValue(
            $settings.contentAlignment.group
          )};
          width: 100%;
          height: 100%;
        }
      }
    }
  `;
});

export default StyledMultiMenuHorizontal;
