import { createContext, useContext, useMemo, useState } from "react";

export type EffectsData = {
  height?: number;
  sumHeight?: number;
  sectionUuid?: string;
  sectionId?: number;
  sticky?: boolean;
  offset?: number;
  isHeaderSection?: boolean;
  zIndex?: number;
  ref?: React.RefObject<HTMLDivElement>;
};

export const SectionEffectsContext = createContext<{
  effectsData: EffectsData[];
  isAnySectionSticky: boolean;
  setSectionEffectsData: (
    sectionUuid: string,
    height: number,
    ref?: React.RefObject<HTMLDivElement>
  ) => void;
}>({
  effectsData: [],
  isAnySectionSticky: false,
  setSectionEffectsData: () => null,
});

export function useSectionEffectsContext() {
  const context = useContext(SectionEffectsContext);
  if (!context) {
    console.log(
      "useSectionEffectsContext must be used under StickyOffsetsContext.Provider"
    );
  }
  return context;
}

export const SectionEffectsProvider = ({
  children,
  effects,
}: {
  effects: EffectsData[];
  children: React.ReactNode;
}) => {
  const [localEffectsData, setLocalEffectsData] = useState<EffectsData[]>([]);

  const effectsData = useMemo(() => {
    const updatedEffects = [...effects];

    return updatedEffects.map((effect) => {
      const localEffect = localEffectsData.find(
        (local) => local.sectionUuid === effect.sectionUuid
      );
      if (localEffect?.height || localEffect?.ref) {
        return { ...effect, height: localEffect.height, ref: localEffect.ref };
      }
      return effect;
    });
  }, [effects, localEffectsData]);

  const setSectionEffectsData = (
    sectionUuid: string,
    height: number,
    ref?: React.RefObject<HTMLDivElement>
  ) => {
    setLocalEffectsData((prev) =>
      prev
        .filter((e) => e.sectionUuid !== sectionUuid)
        .concat({ sectionUuid, height, ref })
    );
  };

  const calculatedEfectsData = useMemo(() => {
    let sumHeight = 0;
    return effectsData.map((effect, idx) => {
      let offset = 0;

      if (effect.sticky) {
        offset = effectsData
          .slice(0, idx)
          .filter((e) => e.sticky)
          .reduce((sum, e) => sum + (e.height || 0), 0);
      }

      sumHeight += effect.height || 0;

      return {
        ...effect,
        offset,
        sumHeight,
      };
    });
  }, [effectsData]);

  return (
    <SectionEffectsContext.Provider
      value={{
        effectsData: calculatedEfectsData,
        setSectionEffectsData,
        isAnySectionSticky: effects.some((offset) => offset.sticky),
      }}
    >
      {children}
    </SectionEffectsContext.Provider>
  );
};
