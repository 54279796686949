import { TLinkReadoutValue } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import ConditionalWrapper from "../global/components/ConditionalWrapper/ConditionalWrapper";
import LinkWrapper from "../global/components/LinkWrapper/LinkWrapper";

export type TemporaryDescendant = Paragraph | Link;

interface SlateNode {
  align: string;
  text: string;
  bold: boolean;
  italic: boolean;
  underline: boolean;
  strikethrough: boolean;
  children: (Paragraph | Link)[];
}

interface Paragraph extends SlateNode {
  type: "paragraph";
}

interface Link extends SlateNode {
  type: "link";
  url: string;
  openInNewTab: boolean;
}

export const serializeHTML = (
  node: TemporaryDescendant,
  index: number,
  linkStyle?: TLinkReadoutValue
): JSX.Element => {
  if (node.text) {
    const text = node.text;

    const bold = (
      <ConditionalWrapper
        condition={node.bold}
        wrapper={(children: React.ReactNode) => <strong>{children}</strong>}
      >
        <>{text}</>
      </ConditionalWrapper>
    );

    const italic = (
      <ConditionalWrapper
        condition={node.italic}
        wrapper={(children: React.ReactNode) => <i>{children}</i>}
      >
        {bold}
      </ConditionalWrapper>
    );

    const underline = (
      <ConditionalWrapper
        condition={node.underline}
        wrapper={(children: React.ReactNode) => <u>{children}</u>}
      >
        {italic}
      </ConditionalWrapper>
    );

    const strikethrough = (
      <ConditionalWrapper
        key={index}
        condition={node.strikethrough}
        wrapper={(children: React.ReactNode) => <s>{children}</s>}
      >
        {underline}
      </ConditionalWrapper>
    );

    return strikethrough;
  } else {
    //Here we used Descendant typing but it caused typing errors so temporarly we added TemporaryDescendant type.
    const children = node.children?.map(
      (n: TemporaryDescendant, index: number) =>
        serializeHTML(n, index, linkStyle)
    );
    switch (node.type) {
      case "paragraph":
        return (
          <p key={index} className={node.align}>
            {children}
          </p>
        );
      case "link":
        if ("url" in node) {
          return (
            <LinkWrapper
              openInNewTab={node.openInNewTab}
              key={index}
              href={node.url}
              linkStyle={linkStyle ?? "basic"}
              renderAs="span"
            >
              {children}
            </LinkWrapper>
          );
        }
        return <span key={index} />;
      default:
        return <span key={index} />;
    }
  }
};
