import styled, { css } from "styled-components";
import { MULTI_MENU_TEMPLATE } from "../../boxes/BoxMultiMenu/StyledMultiMenu";
import { IBoxMultiMenuSettings } from "../../boxes/BoxMultiMenu/StyledMultiMenu.types";
import composeCss, { alignmentOptionsToValue } from "../../composeCss";
import { convertColorIdToHex } from "../../settingsPatterns.methods";

const StyledMultiMenuList = styled.div<
  IBoxMultiMenuSettings & { activeGroup: number }
>(({ $settings, activeGroup, theme }) => {
  const {
    spacing: {
      itemHeader: {
        top: itemHeaderTop,
        right: itemHeaderRight,
        bottom: itemHeaderBottom,
        left: itemHeaderLeft,
      },
      itemHeaderGap,
    },
  } = $settings;
  return css`
    .groups {
      display: grid;
      grid-template-columns: repeat(${$settings.groups.length}, 1fr);
      ${itemHeaderGap !== 0 && `column-gap: ${itemHeaderGap}px;`}
      ${itemHeaderTop !== 0 && `padding-top: ${itemHeaderTop}px;`}
      ${itemHeaderRight !== 0 && `padding-right: ${itemHeaderRight}px;`}
      ${itemHeaderBottom !== 0 && `padding-bottom: ${itemHeaderBottom}px;`}
      ${itemHeaderLeft !== 0 && `padding-left: ${itemHeaderLeft}px;`}
      justify-content: ${alignmentOptionsToValue(
        $settings.contentAlignment.group
      )};
      text-align: ${$settings.contentAlignment.columns.toLowerCase()};
      background-color: ${convertColorIdToHex(
        $settings.background.color,
        theme.colorPalette
      )};
      border: ${composeCss.border(
        { color: $settings.border.color, width: $settings.border.size },
        theme
      )};
      ${$settings.shadow ? `box-shadow: ${MULTI_MENU_TEMPLATE.shadow}` : ""};
      ${$settings?.groups?.[activeGroup] !== undefined
        ? composeCss.width(
            $settings.groups[activeGroup].width,
            undefined,
            theme
          )
        : "100%"};
      li {
        list-style-type: none;
      }
      & > li {
        display: flex;
        flex-direction: column;
        align-items: ${alignmentOptionsToValue(
          $settings.contentAlignment.group
        )};
      }
      & > a {
        display: flex;
        height: 100%;
        padding: ${MULTI_MENU_TEMPLATE.groupPadding};
      }
      .multi-menu-container__list__heading {
        text-align: ${$settings.contentAlignment.group.toLowerCase()};
      }

      .columns {
        width: 100%;
        & > li {
          width: 100%;
          display: flex;
          justify-content: ${alignmentOptionsToValue(
            $settings.contentAlignment.columns
          )};
        }
      }
    }
  `;
});

export default StyledMultiMenuList;
