import styled, { css } from "styled-components";
import composeCss from "../../composeCss";
import { IBoxSliderSettings } from "./StyledSlider.types";
import { MOBILE_BREAKPOINT } from "../../../global/global";
import { IThemeState } from "@ecp-redux/dto/themeSettings/themeSettings.types";

export const generateBoxSliderStyles = ({
  $settings,
  theme,
}: {
  $settings: IBoxSliderSettings["$settings"];
  theme: IThemeState;
}) => {
  const pageWidth = theme?.stylePages?.pages.maxWidth ?? 1920;
  const cssStyles = css`
    position: relative;
    display: flex;

    @media (max-width: ${MOBILE_BREAKPOINT}px) {
      height: ${$settings.size === "CUSTOM"
        ? `${$settings.customHeight}px`
        : `${$settings.size}px`};
    }

    height: clamp(
      0px,
      calc((100vw - 0px) * ${$settings.customHeight / pageWidth}),
      ${$settings.customHeight}px
    );

    margin: ${composeCss.margin($settings.margin)};

    .swiper {
      width: 100%;
      margin: 0;
    }

    .swiper-container {
      height: 100%;
      width: 100% !important;
    }

    .swiper-wrapper {
      width: 100%;
      height: 100%;
      -webkit-transform-style: preserve-3d;
      transform-style: preserve-3d;
    }

    .swiper-slide {
      display: flex;
      width: 100%;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }

    .swiper-button-prev,
    .swiper-button-next {
      color: #81889c !important;
      height: 40px;
      width: 40px;
      background-color: #ffffff;
      z-index: var(--zindex-swiper-buttons);
      transition: all var(--ecp-transition);
    }

    .swiper-button-prev {
      left: 20px;
    }

    .swiper-button-next {
      right: 20px;
    }

    .swiper-button-prev:hover,
    .swiper-button-next:hover {
      background-color: #f0f5fe;
    }

    .swiper-button-prev:active,
    .swiper-button-next:active {
      background-color: #dce9fd;
    }

    .swiper-button-prev::after,
    .swiper-button-next::after {
      font-size: 23px;
    }

    .swiper-button-prev.swiper-button-disabled,
    .swiper-button-next.swiper-button-disabled {
      pointer-events: auto !important;
    }

    .swiper-pagination-bullet:not(.pagination-counter) {
      height: 12px;
      width: 12px;
      background-color: #ffffff;
      opacity: 1;
      transition: all var(--ecp-transition);
    }

    .swiper-pagination-bullet-active {
      background-color: #81889c !important;
    }

    .pagination-counter,
    .pagination-counter.swiper-pagination-bullet-active {
      width: 20px;
      height: auto;
      border-radius: 0;
      box-sizing: border-box;
      background-color: #ffffff;
      padding: 2px;
      font-size: 16px;
      font-weight: 600;
      user-select: none;
    }

    .pagination-counter {
      color: #81889c;
      cursor: pointer;
      opacity: 1;
      transition: all var(--ecp-transition);
    }

    .pagination-counter:not(.swiper-pagination-bullet-active):hover {
      background-color: #f0f5fe;
    }

    .pagination-counter:not(.swiper-pagination-bullet-active):active {
      background-color: #dce9fd;
    }

    .pagination-counter.swiper-pagination-bullet-active {
      color: #293740;
      cursor: auto;
    }
  `;

  return cssStyles;
};

export const StyledSlider = styled.div<IBoxSliderSettings>((props) =>
  generateBoxSliderStyles(props)
);
