import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import classNames from "classnames";
import cleanDeep from "clean-deep";
import merge from "lodash.merge";
import { useTheme } from "styled-components";
import { IPortalSection, IPortalSlot, SectionType } from "@ecp-pageTypes";
import {
  DisplaySettingsSectionScheme,
  IDisplaySettingsSection,
} from "@ecp-redux/dto/themeSettings/StyledSection.types";
import {
  IThemeState,
  RowHeightOption,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { StyledButtonAlignment } from "../../boxes/BoxButton/BoxButton.styled";
import ConditionalWrapper from "../../global/components/ConditionalWrapper/ConditionalWrapper";
import { useObserveElementDimensions } from "../../global/components/ImageWrapper/ImageWrapper.methods";
import { getDefaultsFromZod, isPortalSide } from "../../helpers/helpers";
import {
  StyledLayoutWidth,
  StyledSection,
} from "../../settingsPatterns/section/StyledSection";
import { PortalPath } from "../../shared/portalPath/portalPath";
import StyledButton from "../../shared/styleElements/StyledButton/StyledButton";
import { CustomCssWrapper } from "../CustomCssWrapper/CustomCssWrapper.styled";
import SlotsLine from "../Slot/SlotsLine";
import { everySlotsEmpty } from "./Section.methods";
import SectionEffects from "./SectionEffects/SectionEffects";
import { StyledFoldedRow } from "./SectionEffects/SectionEffects.styled";

export const usePrepareSectionSettings = (
  displaySettings: Partial<IDisplaySettingsSection>,
  sectionType: SectionType | undefined
): IDisplaySettingsSection => {
  const themeSettings = useTheme() as IThemeState;
  const displaySettingsWithoutEmptySettings = cleanDeep(displaySettings, {
    nullValues: true,
    emptyStrings: false,
  });
  const displaySettingsPattern = getDefaultsFromZod(
    DisplaySettingsSectionScheme
  );
  const { headerFooter } = themeSettings.stylePages.pages;
  const overrideFromThemeSettings = {
    ...themeSettings.stylePages.section,
    fullWidthBackground:
      (sectionType === SectionType.FOOTER && headerFooter.fullWidthBgcFooter) ||
      (sectionType === SectionType.HEADER && headerFooter.fullWidthBgcHeader),
  };

  const mergedDisplaySettings = merge(
    {},
    displaySettingsPattern,
    overrideFromThemeSettings,
    displaySettingsWithoutEmptySettings
  );

  return mergedDisplaySettings;
};

export interface ISectionProps {
  sectionData: IPortalSection & { mobileSlots?: IPortalSlot[] };
  type: SectionType; // header and footer have option in themeSettings.stylePages to make full width
  path: PortalPath;
}

const Section: React.FC<ISectionProps> = ({ sectionData, type, path }) => {
  const [isRowFolded, setIsRowFolded] = useState(true);
  const [foldedRowChildrenRef, _, folderRowChildrenHeight] =
    useObserveElementDimensions();

  const { asPath: url } = useRouter();

  const displaySettings = usePrepareSectionSettings(
    sectionData.displaySettings,
    type
  );

  const isFoldedHigher =
    (folderRowChildrenHeight ?? 0) <
    displaySettings.effects.foldedRow.settings.height;

  const {
    effects: {
      foldedRow: {
        enabled: foldedRowEnabled,
        settings: {
          button: {
            style,
            horizontalAlignment,
            padding,
            collapseLabel,
            expandLabel,
          },
        },
      },
    },
  } = displaySettings;

  const handleRowFold = () => {
    if (isPortalSide()) {
      setIsRowFolded((prevVal) => !prevVal);
    }
  };

  useEffect(() => {
    return () => {
      setIsRowFolded(true);
    };
  }, [url]);

  if (displaySettings.popup.enabled && type !== SectionType.POPUP) {
    return null;
  }

  if (
    sectionData?.slots?.length === 0 &&
    sectionData?.mobileSlots?.length === 0 &&
    displaySettings.height.selectedOption === RowHeightOption.CONTENT
  ) {
    return null;
  }

  if (
    everySlotsEmpty(sectionData) &&
    displaySettings.height.selectedOption === RowHeightOption.CONTENT
  ) {
    return null;
  }

  return (
    <SectionEffects
      sectionData={sectionData}
      type={type}
      key={displaySettings.effects.stickyRow.enabled ? url : undefined}
    >
      <CustomCssWrapper
        data-testid="customCss"
        id="custom-Css"
        customCss={sectionData.customCss}
      >
        <StyledSection
          $settings={displaySettings}
          className={classNames(
            sectionData && `section-${sectionData?.sectionOrder}`,
            !path.isSubsection() && "firstLineSection"
          )}
          data-testid="section"
          id={`SECTION=${sectionData.id}`}
          type={type}
          hoverEffect={displaySettings.effects.hoverEffect}
        >
          <ConditionalWrapper
            condition={foldedRowEnabled}
            wrapper={(children) => (
              <StyledFoldedRow
                $displaySettings={displaySettings}
                isRowFolded={isRowFolded && !isFoldedHigher}
              >
                <div
                  className="folded-row-wrapper"
                  data-testid="folded-row-wrapper"
                >
                  <div ref={foldedRowChildrenRef}>{children}</div>
                </div>

                {!isFoldedHigher && (
                  <StyledButtonAlignment
                    alignment={horizontalAlignment}
                    padding={padding}
                    className="expand-btn-wrapper"
                  >
                    <StyledButton $settings={style} onClick={handleRowFold}>
                      <span>{isRowFolded ? expandLabel : collapseLabel}</span>
                    </StyledButton>
                  </StyledButtonAlignment>
                )}
              </StyledFoldedRow>
            )}
          >
            <>
              {displaySettings.fullWidthBackground && (
                <div id="overlay-section" />
              )}
              <StyledLayoutWidth id="layout-width" $settings={displaySettings}>
                {!displaySettings.fullWidthBackground && (
                  <div id="overlay-section" />
                )}
                <SlotsLine
                  slots={sectionData.slots}
                  $settings={displaySettings}
                  type={type}
                  path={path}
                />
                {sectionData.mobileSlots && (
                  <SlotsLine
                    slots={sectionData.mobileSlots}
                    $settings={displaySettings}
                    mobileSecondLine
                    type={type}
                    path={path}
                  />
                )}
              </StyledLayoutWidth>
            </>
          </ConditionalWrapper>
        </StyledSection>
      </CustomCssWrapper>
    </SectionEffects>
  );
};

export default Section;
