import { isPortalSide } from "../../../helpers/helpers";
import { StyledLoader } from "./Loader.styled";
import { LoaderProps } from "./Loader.types";

const Loader = ({
  size,
  borderSize,
  borderColor,
  fullScreen,
  style,
}: LoaderProps) => {
  return isPortalSide() ? (
    <StyledLoader
      size={size}
      borderSize={borderSize}
      borderColor={borderColor}
      fullScreen={fullScreen}
      className="loader"
      style={style}
    >
      <span className="loader__spinner" />
    </StyledLoader>
  ) : null;
};

export default Loader;
