import { useTheme } from "styled-components";
import { IThemeState } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import {
  checkBoolean,
  formatNumberWithCap,
  formatPrice,
  handleKeyDown,
} from "../../helpers/helpers";
import { isNotEmptyMessage } from "../../helpers/isNotEmptyMessage";
import Loader from "../../shared/components/Loader/Loader";
import useIsMobilePortal from "../../shared/hooks/useIsMobilePortal";
import StyledButton from "../../shared/styleElements/StyledButton/StyledButton";
import StyledText from "../../shared/styleElements/StyledText/StyledText";
import { StyledBubble } from "./BoxMiniCart.styled";
import {
  IBoxMiniCartMessages,
  IBoxMiniCartSettings,
} from "./BoxMiniCart.types";
import { IBoxMiniCart } from "./useBoxMiniCart";
import { useImageSize } from "@ecp-boxes/global/components/ImageWrapper/ImageWrapper.methods";
import { useEffect, useMemo, useRef, useState } from "react";
import { useRouter } from "next/router";

interface IMiniCartSimpleProps {
  handleMouseClick: () => void;
  settings: IBoxMiniCartSettings;
  messages: IBoxMiniCartMessages;
  miniCartCount: IBoxMiniCart["miniCartCount"];
  miniCartContent: IBoxMiniCart["miniCartContent"];
}

export const MiniCartDetailed = ({
  settings,
  handleMouseClick,
  messages,
  miniCartCount,
  miniCartContent,
}: IMiniCartSimpleProps) => {
  const {
    advanceSettings: {
      messages: { currencyShort },
    },
  } = useTheme() as IThemeState;
  const [bubbleRef, { height: bubbleHeight, y: bubbleY }] = useImageSize();
  const [containerRef, { height: containerHeight, y: containerY }] =
    useImageSize();
  const [memoizedContainerDimensions, setMemoizedContainerDimensions] =
    useState({
      height: containerHeight,
      y: containerY,
    });
  const router = useRouter();

  const isMobile = useIsMobilePortal();

  const { mini_cart_with_value_savings_label } = messages;
  const { count, isMiniCartCountLoading } = miniCartCount;

  const {
    mini_cart_with_value_savings_activity,
    mini_cart_with_value_savings_label_color,
    mini_cart_with_value_savings_label_typo,
    mini_cart_with_value_savings_color,
    mini_cart_with_value_savings_typo,
    mini_cart_with_value_bubble_count_typo,
    mini_cart_with_value_bubble_count_color,
    mini_cart_with_value_value_color,
    mini_cart_with_value_value_typo,
    mini_cart_with_value_bubble_x_offset,
    mini_cart_with_value_bubble_y_offset,
  } = settings;

  const { cartValueAfterDiscounts, cartValueFromProducts } =
    miniCartContent?.content?.cartSummary ?? {};

  const { isMiniCartLoading } = miniCartContent;

  useEffect(() => {
    if (!memoizedContainerDimensions.height) {
      setMemoizedContainerDimensions({
        height: containerHeight,
        y: containerY,
      });
    }
  }, [containerHeight, router.asPath]);

  const savedAmount =
    cartValueFromProducts && cartValueAfterDiscounts
      ? cartValueFromProducts - cartValueAfterDiscounts
      : null;

  const calculatedContainerHeight = useMemo(() => {
    console.log(
      memoizedContainerDimensions.y,
      bubbleY,
      memoizedContainerDimensions.height
    );
    if (
      memoizedContainerDimensions.y === undefined ||
      bubbleY === undefined ||
      memoizedContainerDimensions.height === undefined
    )
      return null;
    const offsetDifference = memoizedContainerDimensions.y - bubbleY;

    return offsetDifference > 0
      ? memoizedContainerDimensions.height + offsetDifference
      : memoizedContainerDimensions.height;
  }, [
    count,
    router.asPath,
    bubbleY,
    memoizedContainerDimensions.y,
    memoizedContainerDimensions.height,
  ]);

  return (
    <div
      className="mini-cart-detailed"
      id="mini-cart-detailed"
      data-testid="mini-cart-detailed"
    >
      <Loader
        size={24}
        borderColor="lightgray"
        borderSize={2}
        style={{
          display:
            !isMiniCartLoading && !isMiniCartCountLoading ? "none" : "block",
        }}
      />
      <div
        ref={containerRef}
        className="mini-cart-detailed__container"
        data-testid="button-open-minicart"
        role="button"
        tabIndex={0}
        onClick={handleMouseClick}
        onKeyDown={(e) => handleKeyDown(e, handleMouseClick)}
        style={{
          height:
            count > 0 && calculatedContainerHeight !== null
              ? calculatedContainerHeight
              : "max-content",
          visibility:
            !isMiniCartLoading && !isMiniCartCountLoading
              ? "visible"
              : "hidden",
        }}
      >
        <StyledButton
          $settings={settings.mini_cart_icon_button}
          renderAs={"button"}
          data-testid="detailed-dropdown-button"
          className="mini-cart-detailed__container__cart-icon"
          show={isNotEmptyMessage(messages.mini_cart_icon_open_label)}
        >
          <StyledBubble
            settings={settings}
            ref={bubbleRef}
            style={{
              width: bubbleHeight,
              visibility:
                !isMiniCartLoading && !isMiniCartCountLoading && count > 0
                  ? "visible"
                  : "hidden",
            }}
          >
            <StyledText
              data-testid="bubble-count"
              $settings={{
                font: mini_cart_with_value_bubble_count_typo,
                text: {
                  color: mini_cart_with_value_bubble_count_color,
                },
              }}
            >
              {formatNumberWithCap(count ?? 0)}
            </StyledText>
          </StyledBubble>
        </StyledButton>
        {typeof cartValueAfterDiscounts === "number" && !isMobile && (
          <StyledText
            className="mini-cart-detailed__container__value"
            $settings={{
              font: mini_cart_with_value_value_typo,
              text: {
                color: mini_cart_with_value_value_color,
              },
            }}
          >
            {formatPrice(cartValueAfterDiscounts, currencyShort)}
          </StyledText>
        )}
      </div>

      {checkBoolean(mini_cart_with_value_savings_activity) &&
        !isMobile &&
        typeof savedAmount === "number" &&
        savedAmount > 0 && (
          <span className="mini-cart-detailed__you-save-description">
            <StyledText
              $settings={{
                font: mini_cart_with_value_savings_label_typo,
                text: {
                  color: mini_cart_with_value_savings_label_color,
                },
              }}
            >
              {mini_cart_with_value_savings_label}
            </StyledText>
            <StyledText
              className="mini-cart-detailed__you-save-description__value"
              $settings={{
                font: mini_cart_with_value_savings_typo,
                text: {
                  color: mini_cart_with_value_savings_color,
                },
              }}
            >
              {formatPrice(savedAmount, currencyShort)}
            </StyledText>
          </span>
        )}
    </div>
  );
};
